.container-image-crop {
    height: 100%;
    width: 100%;
}

.container-cropper {
    height: 100%;
    width: 100%;
    padding: 0 20%;
    margin: auto 0;
}

.cropper {
    height: 600px;
    position: relative;
    margin: 20px 0;
}


.slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 60%;
}

.container-buttons {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}