.person-photo-image {
    max-width: 200px;
    max-height: 300px;
    align-content: center;
    text-align: center;
    justify-content: center;
    align-self: center;
}

.fa-check {
    color: #32CD32;;
}

.fa-clock {
    color: #0000CD;;
}

.fa-calendar-times {
    color: #ff0000;
}

.fa-hourglass-half {
    color: #00FF00;
}

.fa-pause {
    color: #FFD700;
}

.fa-times {
    color: #ff0000;
}